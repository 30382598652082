<template>
  <FormMultiSelect
    :label="label"
    :options="options"
    :model-value="selectedClientIds"
    :disabled="disabled"
    can-select-all
    button-full-width
    dropdown-full-width
    placeholder="Select clients"
    @update:model-value="onUpdate"
  />
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { storeToRefs } from "pinia";

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: null,
  },
  selectedClientIds: {
    type: Array as PropType<number[]>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const clientStore = useClientStore();
const { clients } = storeToRefs(clientStore);

const options = computed(() => {
  return clients.value.map((client) => {
    return {
      value: client.id,
      displayValue: client.displayName,
      img: client.displayImage,
    };
  });
});

const emit = defineEmits(["update:selected"]);

const onUpdate = (value: number[]) => {
  if (props.disabled) return;
  emit("update:selected", value);
};
</script>
